import React from 'react';

import Layout from '../../components/Layout';

import pic1 from '../../assets/images/services/seo-services.png';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const SEOOptimization = () => (
  <Layout>
    <div id="main">
      <div className="inner box">
        <h1>SEO Services</h1>
        <span className="image main">
          <img src={pic1} alt="" />
        </span>
        <article className="box">
        <p>
        Every modern SEO strategy is to rank as high as possible on search engines like Google and Bing. 
        The point is to get your website and business found. To put the most relevant information at the 
        forefront and to bring your value proposition to potential customers and visitors as quickly as possible. 
        The first step to doing this is to optimize your on page SEO factors and ensure your website is optimized 
        to work efficiently with the search engines. A critical step to achieving this is understanding 
        how to structure and build a search engine friendly website. 
        </p>
        <Row><Col>
        <Card>
          <Card.Header>Website Performance Tweaking </Card.Header>
            <Card.Body>
              <p>
              Having a fast responsive website is the first step to ranking on search engines. 
              We provide optimization services to reduce your page load times, optimize the delivery 
              of your content and to improve your overall user experience. No one likes a slow website. 
              By utilizing good development practices, I can usually reduce a unoptimized websites loading time by 70%. 
              </p>
            </Card.Body>
          </Card></Col><Col>
          <Card>
          <Card.Header>Content Revision and Optimization</Card.Header>
            <Card.Body>
              <p>
              A huge part of on-page SEO is how you structure and present your websites content. 
              The user flow and category hierarchy is important in helping Google and other search 
              engines understand your website. If your site structure is confusing to a regular person 
              then just imagine how it feels to a web crawler bot used by Google to understand your website. 
              We can help you get your user flow and experience in check and help search engines like Google 
              to better understand your website. This results in a more accurate and better web listings on search engines. 
              The better they understand your website - the better the can present it to others!
              </p>
            </Card.Body>
          </Card></Col><Col>
          <Card>
          <Card.Header>Advertisements and Customer acquisition strategies</Card.Header>
            <Card.Body>
              <p>
                Do you need help with online marketing and generating website traffic? 
                We do that as well! Adwords campaign and keyword marketing. When organic search isnt enough, 
                pay your way to the top of Google search to gain clicks and website traffic. 
                Setup goals and push traffic to meet those goals. Target past site visitors to get repeat 
                business and recover lost customers. More traffic equals more calls and more sales.
              </p>
            </Card.Body>
          </Card>
          </Col></Row>
        </article>
      </div>
    </div>
  </Layout>
);

export default SEOOptimization;